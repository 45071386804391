@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./GillSans.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: bold;
  font-weight: normal;
  src: url('./GillSansBold.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: normal;
  src: url('./GillSansItalic.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: heavy;
  src: url('./GillSansHeavy.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: heavy;
  src: url('./GillSansHeavyItalic.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: light;
  src: url('./GillSansLight.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: light;
  src: url('./GillSansLightItalic.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: medium;
  src: url('./GillSansMedium.otf') format('otf');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: medium;
  src: url('./GillSansMediumItalic.otf') format('otf');
}