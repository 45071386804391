@import url("./font/font.css");

:root {
  --bg: #FAFAFA;
  --primarydark: #3E0005;
  --primarylight: #720008;
  --secondary: #A6373F;
  --secondarylight: #DA9196;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg);
  color: rgba(10, 3, 0, 0.8);
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

div#app span.construction {
  display: block;
  width: 100%;
  text-align: center;
  margin: 40px 0;
  font-size: 1.3em;
  color: rgba(10, 3, 0, 0.4);
}

.landing {
  height: 100vh;
  width: 100vw;
  background-color: var(--bg);
}

.landing button{
  position: absolute;
  z-index: 1;
  right: 20%;
  bottom: 20%;
  border: 2px solid var(--primarylight);
  padding: 14px 20px;
  font-size: 1.5rem;
  background-color: var(--primarylight);
  color: var(--bg);
  border-radius: 0.1rem;
  font-weight: 500;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.landing button:hover {
  cursor: pointer;
  background-color: rgba(114,0,8,0.9);
}

.landing img {
  object-fit: cover;
  object-position: 9% ;
  height: 100vh;
  min-width: 100vw;
  position: absolute;
  z-index: 1;
  display: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.bio, .year {
  width: 90%;
  max-width: 500px;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

address, h1, h2, h3, h4, .leftbioside, .navigation {
  width: 90vw;
  max-width: 1100px;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

h1 {
  text-align: center;
  color: var(--primarydark);
  font-size: 2.3rem;
  font-weight: 300;
  padding-top: 40px;
  padding-bottom: 20px;
  animation: fadeIn ease 1s;
}

h2 {
  color: rgba(10, 3, 0, 0.8);
  margin-top: 35px;
  border-bottom: 2px solid rgba(10, 3, 0, 0.4);
  padding-bottom: 10px;
  width: auto;
  font-weight: 400;
  color: var(--secondary);
}

h3 {
  color: var(--secondary);
  font-weight: 400;
  width: 25%;
}

h3.currentLiving {
  width: 100%;
  color: rgba(10, 3, 0, 0.8);
  font-weight: 300;
}

p {
  width: 75%;
  font-weight: 300;
}

h3, p {
  font-size: 1.2em;
}

.year {
  display: flex;
  margin-bottom: 0px;
}

label, a {
  display: block;
  padding-bottom: 20px;
  font-style: normal;
}

a {
  color: var(--primarylight);
}

section.contact {
  width: 50%;
  font-weight: 300;
}

div.p4 div.leftbioside div.navigation {
  margin-bottom: 20px;
}

section.contact h3, section.contact h3 span {
  display: inline;
  font-size: 1.3em;
  font-weight: 300;
}

section.contact h3 span {
  color: rgba(10, 3, 0, 0.8);
  font-weight: 300;
  font-size: 0.7em;
  margin-left: 10px;
}

address {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-bottom: 40px;
  font-size: 1.2rem;
  width: 50%;
}

address div {
  width: 100%;
  margin-bottom: 40px;
}

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.navigation button {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 0;
  border-radius: 0.1rem;
  outline:none;
}

.navigation button svg {
  width: 2em;
  stroke: var(--secondary);
}

.navigation button:hover {
  color:var(--primarylight);
  cursor: pointer;
  border-bottom: 2px solid var(--secondary);

}

.navigation .pageforward {
  margin-left: auto;
}

.navigation .pageback {
  margin-right: auto;
}

.leftbioside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.leftbioside .imagediv {
  width: 45%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
} 

.leftbioside .imagediv label {
  margin: 15px 0;
  color: rgba(10, 3, 0, 0.7);
  text-align: right;
  font-weight: 300;
}

.leftbioside .imagediv img {
  max-width: 400px;
  width: 90%;
}

@media screen and (max-width: 900px) {
  address, h1, h2, h3, h4, .leftbioside .navigation {
    width: 95vw;
  }
  h3, p{
  width: 100%;
  }
  .year {
    flex-wrap: wrap;
  }
  .landing {
    overflow: hidden;
  }
  .landing img {
   object-position: 50%; 
   width: 97%;
  }
  .leftbioside .imagediv {
    display: none;
  }
  address {
    flex-wrap: wrap;
  }
  address div {
    width: 95%;
  }
  address div a, address div label {
    text-align: center;
  }

  section.contact  {
    width: 100%;
    text-align: center;
  }

  section.contact h3, section.contact h3 span {
    display: block;
    margin-top: 20px;
    margin-right: 0;
  }
}


@keyframes fadeIn {
0% {
  opacity:0;
}
100% {
  opacity:1;
}
}

@-moz-keyframes fadeIn {
0% {
  opacity:0;
}
100% {
  opacity:1;
}
}

@-webkit-keyframes fadeIn {
0% {
  opacity:0;
}
100% {
  opacity:1;
}
}

@-o-keyframes fadeIn {
0% {
  opacity:0;
}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {
  opacity:0;
}
100% {
  opacity:1;
}
}


@keyframes riseIn {
  0% {
    opacity:0;
    transform: translate(0, -30px);
  }
  100% {
    opacity:1;
    transform: translate(0, 0);
  }
}

@-moz-keyframes riseIn {
  0% {
    opacity:0;
    transform: translate(0, -30px);
  }
  100% {
    opacity:1;
    transform: translate(0, 0);
  }
}

@-webkit-keyframes riseIn {
  0% {
    opacity:0;
    transform: translate(0, -30px);
  }
  100% {
    opacity:1;
    transform: translate(0, 0);
  }
}

@-o-keyframes riseIn {
  0% {
    opacity:0;
    transform: translate(0, -30px);
  }
  100% {
    opacity:1;
    transform: translate(0, 0);
  }
}

@-ms-keyframes riseIn {
  0% {
    opacity:0;
    transform: translate(0, -30px);
  }
  100% {
    opacity:1;
    transform: translate(0, 0);
  }
}